import intlTelInput from 'intl-tel-input';
import de from 'intl-tel-input/i18n/de';

export default () => ({
    input: null,
    model: null,
    itit: null,

    init() {
        this.input = this.$root.closest('input[type="tel"]');
        this.model = this.input.getAttribute('wire:model') || this.input.getAttribute('wire:model.defer');

        this.itit = intlTelInput(this.input, {
            i18n: de,
            initialCountry: 'de',
            nationalMode: true,
            validationNumberType: null,
            loadUtilsOnInit: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.8.1/build/js/utils.js',
        });

        this.input.addEventListener('change', () => {
            this.$wire.set(this.model, this.itit.isValidNumber() ? this.itit.getNumber() : this.input.value, true);
        });
        this.input.addEventListener('blur', () => {
            this.$wire.set(this.model, this.itit.isValidNumber() ? this.itit.getNumber() : this.input.value, true);
        });
    },
});
