export default () => ({
    init() {
        this.set();
        Livewire.on('form:update', () => this.set());
    },

    set() {
        this.startDate = document.getElementById('startDate');
        this.endDate = document.getElementById('endDate');

        this.whenEmpty();

        this.$root.addEventListener('focus', event => {
            if (this.$root.getAttribute('type') !== 'date') {
                this.$root.setAttribute('type', 'date');
            }
            if (this.$root.classList.contains('with-placeholder')) {
                this.$root.classList.remove('with-placeholder');
            }
            this.$root.showPicker();
        });

        this.$root.addEventListener('blur', event => {
            if (!event.target.value.length) {
                this.whenEmpty();
            }
            if (event.target.value.length && this.$root.id === 'startDate' && document.getElementById('endDate')) {
                document.getElementById('endDate').setAttribute('min', event.target.value);
            }
        });
    },

    whenEmpty() {
        if (!this.$root.value.length) {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.$root.classList.add('with-placeholder');
            } else {
                this.$root.setAttribute('type', 'text');
            }
        }
    }
});
