import Alpine from '@alpinejs/csp';
import Tooltip from '@ryangjchandler/alpine-tooltip';
import {throttle} from 'lodash';
import accommodationModalButton from './accommodation-modal-button';
import alert from './alert';
import autogrowInput from './autogrow-input';
import back from './back';
import copy from './copy';
import employeeModalButton from './employee-modal-button';
import errorNotification from './error-notification';
import exclusiveCheckboxes from './exclusive-checkboxes';
import fadedText from './faded-text';
import goToStep from './go-to-step';
import goToTop from './go-to-top';
import imageCaption from './image-caption';
import link from './link';
import mainNav from './main-nav';
import modal from './modal';
import modalButton from './modal-button';
import notification from './notification';
import pagination from './pagination';
import scrollTo from './scroll-to';
import secondaryNav from './secondary-nav';
import share from './share';
import swipePagination from './swipe-pagination';
import tiles from './tiles';
import toggle from './toggle';
import trackFeature from './track-feature';
import tourGuideModalButton from './tour-guide-modal-button';
import tripDates from './trip-dates';
import validate from './validate';
import dateField from './date-field';
import phoneInput from './phone-input';
import './utilities';
import './listeners';
import './cmp';
import {hideAll} from 'tippy.js';

window.GOOGLE_MAPS_API_KEY = 'AIzaSyBr5NMWg4_K1oFqij5Msg1tlvX1-QUKSe0';

Alpine.plugin(Tooltip.defaultProps({}));

window.Alpine = Alpine;

window.Alpine.data('input', () => ({
    selectAll: event => event.target.select()
}));

window.Alpine.data('accommodationModalButton', accommodationModalButton);
window.Alpine.data('alert', alert);
window.Alpine.data('autogrowInput', autogrowInput);
window.Alpine.data('back', back);
window.Alpine.data('copy', copy);
window.Alpine.data('employeeModalButton', employeeModalButton);
window.Alpine.data('errorNotification', errorNotification);
window.Alpine.data('exclusiveCheckboxes', exclusiveCheckboxes);
window.Alpine.data('goToStep', goToStep);
window.Alpine.data('goToTop', goToTop);
window.Alpine.data('fadedText', fadedText);
window.Alpine.data('imageCaption', imageCaption);
window.Alpine.data('link', link);
window.Alpine.data('mainNav', mainNav);
window.Alpine.data('modal', modal);
window.Alpine.data('modalButton', modalButton);
window.Alpine.data('notification', notification);
window.Alpine.data('pagination', pagination);
window.Alpine.data('scrollTo', scrollTo);
window.Alpine.data('secondaryNav', secondaryNav);
window.Alpine.data('share', share);
window.Alpine.data('swipePagination', swipePagination);
window.Alpine.data('tiles', tiles);
window.Alpine.data('toggle', toggle);
window.Alpine.data('tourGuideModalButton', tourGuideModalButton);
window.Alpine.data('trackFeature', trackFeature);
window.Alpine.data('tripDates', tripDates);
window.Alpine.data('validate', validate);
window.Alpine.data('dateField', dateField);
window.Alpine.data('phoneInput', phoneInput);
window.Alpine.data('x', () => {
});

window.Alpine.start();

window.livewire.onError(statusCode => {
    if (statusCode === 419) {
        window.location.reload();
    }
});

window.livewire.onPageExpired((response, message) => window.location.reload());

if (window.isTouchDevice()) {
    window.addEventListener('scroll', throttle(hideAll, 200), {passive: true});
}

window.addEventListener('DOMContentLoaded', () => {
    if (location.hash) {
        window.dispatchEvent(new CustomEvent('app:scroll-to', {detail: {selector: location.hash}}));
    }
});

// new PerformanceObserver(entryList => {
//     for (const entry of entryList.getEntries()) {
//         console.log('Layout shift:', entry);
//     }
// }).observe({ type: 'layout-shift', buffered: true });
